import React from 'react'
import Layout from "../components/Layout"
import SEO from '../components/seo'
import EducationDynamicsBannersBlock from "../components/EducationDynamicsBanner";

const Search = (props) => {
  const params = new URLSearchParams(props.location.search);

  const degree = (params.get("degree")) || null;
  const category = (params.get("category")) || null;
  // const subject = (params.get("subject")) || null; // @TODO: define subjects mapping.

  return(
      <>
        <Layout>
          <SEO title="Search | Academic Info" />

          <h1>
            Search
          </h1>

          <EducationDynamicsBannersBlock
              searchMode
              categories={!!category && category.split()}
              degrees={!!degree && degree.split()}
          />
        </Layout>
      </>
  );
};

export default Search
