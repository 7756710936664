import React, { useEffect, useState } from "react"
import styles from './styles.module.scss'
import arrowSrc from '../../assets/img/arrow-right.svg'

const EducationDynamicsBannersBlock = ({ degrees, categories, subCategories, specialties, searchMode, top, bottom }) => {

  const edDyAPIPath = '/.netlify/functions/eddy';

  const[loading, setLoading] = useState(true);
  const[response, setResponse] = useState(null);
  const[institutions, setInstitutions] = useState([]);

  // Retrieve Termly status from local store.
  const termlyStore = typeof window !== 'undefined' && JSON.parse(window.localStorage.getItem('TERMLY_API_CACHE'));
  const adsEnabled = termlyStore?.TERMLY_COOKIE_CONSENT?.value?.advertising;
  const analyticsEnabled = termlyStore?.TERMLY_COOKIE_CONSENT?.value?.analytics;

  useEffect(() => {
    const currentUrl = typeof window !== 'undefined' && window.location.href;

    (async () => {
      // Reset states.
      setInstitutions([]);
      setLoading(true);

      let clientIP = '';
      let clientZipCode = '';

      // If termly accepted, get client IP and/or ZipCode.
      if (!!(adsEnabled || analyticsEnabled)) {
        const clientIpRequest = await fetch(
            'https://geolocation-db.com/json/85249190-4601-11eb-9067-21b51bc8dee3'
        );
        const clientData = await clientIpRequest.json();
        clientIP = clientData.IPv4 || '';
        clientZipCode = clientData.postal || '';
      }

      // Get institutions request.
      const rawResponse = await fetch(
          edDyAPIPath,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            body: JSON.stringify({
              "userIp": clientIP,
              "siteUrl": currentUrl,
              "zipCode": clientZipCode,
              "categories": !!categories?.length ? categories.join(',') : null,
              "subcategories": !!subCategories?.length ? subCategories.join(',') : null,
              "specialties": !!specialties?.length ? specialties.join(',') : null,
              "desiredegreelevel": !!degrees?.length ? degrees.join(',') : null
            })
          }
      );

      const content = await rawResponse.json();

      setLoading(false);

      if (rawResponse.status === 200) {
        setResponse(content);

        if (content.Institutions?.length > 0) {
          // For search page show all results, otherwise select only first 3 institutions (+5 at the bottom if any).

          if (searchMode) {
            // Show all results.
            setInstitutions(content.Institutions);
          }
          else if (top) {
            // Show top 3 institutions at the top of the page.
            setInstitutions(content.Institutions.slice(0, 3));
          }
          else if (bottom) {
            // Show next 5 institutions (#4 - #8) at the bottom of the page if available.
            setInstitutions(content.Institutions.slice(3, 8));
          }
        }
      }
      else {
        console.table(content);
      }

    })();
  }, [degrees, categories, subCategories, specialties]);

  const trimTextToWordsNumber = (text, wordsCount) => {
    let result = text;
    let wordsArray = text.split(' ');

    if (wordsArray.length > wordsCount) {
      wordsArray = wordsArray.slice(0, wordsCount);
      result = wordsArray.join(' ') + '...';
    }

    return result;
  };

  return (
    <>
      {/* Hide if it's a bottom banner without institutions. */}
      {!(bottom && !institutions.length && !loading) &&
          <div className={styles.EducationDynamicsBanner}>

            <section className={styles.bannersWrapper}>

              <div className={styles.header}>
                <h3 className={styles.heading}>Degree Programs you Might Like</h3>

                <span className={styles.subheading}>sponsored results</span>
              </div>

              {!!loading &&
              <div className={styles.loadingSpinner}/>
              }

              {!!response && institutions.length > 0
                  ? institutions.map((institution, index) => (
                    <div className={styles.banner} key={`institution-banner--${index}--${institution.InstitutionName}`}>
                      <div className={styles.linkBox}>
                        {!!institution.LogoSmallImage &&
                            <a
                                className={styles.image}
                                href={institution.ClickThroughUrl}
                                target="_blank"
                                rel="noreferrer nofollow"
                            >
                              <img
                                  className={styles.image}
                                  src={institution.LogoSmallImage}
                                  alt={`${institution.InstitutionName} logo`}
                              />
                            </a>
                        }

                        {!!institution.ClickThroughUrl &&
                            <a
                                className={styles.button}
                                href={institution.ClickThroughUrl}
                                target="_blank"
                                rel="noreferrer nofollow"
                            >
                              Visit Site
                            </a>
                        }
                      </div>

                      <div className={styles.contentBox}>
                        <a
                            className={styles.headingLink}
                            href={institution.ClickThroughUrl}
                            target="_blank"
                            rel="noreferrer nofollow"
                        >
                          <h3 className={styles.heading}>{institution.InstitutionName}</h3>
                        </a>

                        {institution.Programs?.length > 0 &&
                            <div className={styles.programs}>
                              {institution.Programs.map((item, index) => (
                                <div key={`institution-program--${index}--${item.name}`} className={styles.programItem}>
                                  <img src={arrowSrc} className={styles.arrow} alt="arrow"/>

                                  {item.Name}
                                </div>
                              ))}
                            </div>
                        }

                        {!!institution.Description &&
                            <p
                                className={styles.description}
                                dangerouslySetInnerHTML={{__html: trimTextToWordsNumber(institution.Description, 42)}}
                            />
                        }
                      </div>
                    </div>
                  ))
                  : !loading && (
                    <span className={styles.error}>No institution suggestions found</span>
                  )
              }
            </section>
          </div>
      }
    </>
  );
};

export default EducationDynamicsBannersBlock
